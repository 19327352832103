import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av tabeller`}</h2>
    <p>{`Tabeller brukes når vi vil vise strukturert informasjon under en felles overskrift.`}</p>
    <h3>{`Table`}</h3>
    <p>{`Tabeller må ha en overskrift, som settes med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<TableCaption/>`}</code>{`.
Husk også å bruke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`scope`}</code>{` på kolonnene-overskrifter, f.eks: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<TableHeaderCell scope="col"/>`}</code>{`.`}</p>
    <p>{`Slik ser det ut:`}</p>
    <DSExample name="tables_Table" mdxType="DSExample" />
    <h4>{`Sortering`}</h4>
    <p>{`Tabeller kan også lages sorterbare. Før å sortera brukes en funksjon som `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`children`}</code>{` prop til `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Table/>`}</code>{`. Denne funksjonen får valgt sorteringsnøkkel og sorteringsrekkefølge som input.`}</p>
    <ul>
      <li parentName="ul">{`Sorteringsnøkler defineres i utgangspunktet med `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`sortKey`}</code>{` på kolonnene-overskriftene, f.eks: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`<TableHeaderCell sortKey="name" scope="col">`}</code>{`.`}</li>
      <li parentName="ul">{`Sorteringsrekkefølge defineres med `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`sortOrder`}</code>{`. Gyldige verdier er `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`none`}</code>{`, `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ascending`}</code>{` eller `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`descending`}</code>{`.`}</li>
    </ul>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`sortOrder`}</code>{` vil oppdateres når brukeren klikker på de ulike overskriftene, og dataene sorteres i henhold til disse. Det er også mulig å sende inn en initiell sortering med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`initialSort `}</code>{`.`}</p>
    <DSExample name="tables_Table_sortable" mdxType="DSExample" />
    <h4>{`Ekspanderbare rader`}</h4>
    <p>{`Det er også mulig å lage ekspanderbare rader der det er hensiktsmessig.`}</p>
    <DSExample name="tables_Table_expandable" mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      